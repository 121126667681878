import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './assets/locales/en/translation.json';
import translationPt_BR from './assets/locales/pt_BR/translation.json';

const fallbackLng = ['pt_BR'];
const availableLanguages = ['pt_BR'];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources:{
      pt_BR: { translation: translationPt_BR },
      en: { translation: translationEN },
    },
    fallbackLng,
    debug: true,
    whitelist: availableLanguages,
    detection: { checkWhitelist: true },
    interpolation: { escapeValue: false },
  });

export default i18n;