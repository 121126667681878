import blackMode from '../assets/images/blackmode.png';
import lesign from '../assets/images/lesign.png';
import descomplicaUpgrade from '../assets/images/descomplicaUpgrade.jpeg';
import sinedrol from '../assets/images/sinedrol.jpg';
import mkssistems from '../assets/images/mkssistems.jpg';

export const portifolioMock = {
  data: [
    {
      _id: '001',
      name: 'Black Mode',
      role: 'Lead Developer',
      technologies: 'React · Redux',
      url: 'https://www.blackmode.com.br/',
      image: blackMode,
      description: 'Black Mode é um e-commerce de roupas streetwear que oferece peças exclusivas e de marcas renomadas para homens e mulheres. A loja virtual é comprometida com a qualidade dos produtos, utilizando materiais de primeira linha e um rigoroso controle de qualidade. Além disso, oferece diversas opções de pagamento e entrega em todo o Brasil. Seja para um look casual ou para arrasar na balada, o Blackmode tem o que você precisa para se sentir confiante e estiloso.',
    },
    {
      _id: '002',
      name: 'Lesign',
      role: 'Lead Developer',
      technologies: 'Html · Sass',
      url: 'https://lesign.vercel.app/',
      image: lesign,
      description: 'Lesign é uma empresa especializada em serviços de impressão digital e comunicação visual. A Lesign oferece serviços personalizados que incluem impressão digital em diversos substratos, serviços de comunicação visual como design gráfico e produção de sinalização, além de opções de impressão ecológica. Com uma equipe experiente, tecnologia de ponta e compromisso com a sustentabilidade, a Lesign é uma excelente escolha para projetos de marketing e sinalização que exigem qualidade, eficácia e responsabilidade ambiental.',
    },
    {
      _id: '003',
      name: 'Descomplica Upgrade',
      role: 'Lead Developer',
      technologies: 'React · Style Components',
      url: 'https://mdu-hotmart.vercel.app/',
      image: descomplicaUpgrade,
      description: 'O curso MDU ensina diversas formas de ganhar dinheiro de forma digital, apresentando modelos de negócios que podem ser aplicados no ambiente virtual. O aluno tem a oportunidade de explorar diferentes possibilidades e descobrir qual delas mais se adequa aos seus interesses e habilidades. O mercado digital apresenta uma grande demanda por profissionais qualificados, oferecendo oportunidades de carreira com remunerações atraentes. O curso também aborda temas como marketing digital, gestão de negócios online e técnicas de vendas, preparando o aluno para atuar de forma eficiente e competitiva no mercado digital.',
    },
    {
      _id: '004',
      name: 'Sinedrol',
      role: 'Web Developer',
      technologies: 'Html · Sass · Javascript',
      url: 'https://sinedrol.netlify.app/',
      image: sinedrol,
      description: 'Sinedrol é um suplemento encapsulado de alta tecnologia e performance. Uma fórmula exclusiva com grandes benefícios no ganho de massa magra, emagrecimento, força e definição além de benefícios a saúde, entre eles o controle de apetite e compulsão por doces redução do colesterol, diabetes, reduz a fadiga muscular, prevenção da osteoporose, aumento da libido, entre outros.',
    },
    {
      _id: '005',
      name: 'MKS Sistems',
      role: 'Front-End Developer',
      technologies: 'React · Typescript · Redux Toolkit',
      url: 'https://mks-sistems-steel.vercel.app/',
      image: mkssistems,
      description: 'Sistema criado para um desafio onde deve ser consumido uma API REST de produtos para exibir a lista de produtos da loja.',
    },
  ]
}