export const skillsMock = {
  data: [
    {
      _id: '001',
      name: 'HTML5',
    },
    {
      _id: '002',
      name: 'CSS3',
    },
    {
      _id: '003',
      name: 'JavaScript',
    },
    {
      _id: '004',
      name: 'Typescript',
    },
    {
      _id: '005',
      name: 'React',
    },
    {
      _id: '006',
      name: 'React Native',
    },
    {
      _id: '007',
      name: 'React Hooks',
    },
    {
      _id: '008',
      name: 'React Router',
    },
    {
      _id: '009',
      name: 'React Query',
    },
    {
      _id: '010',
      name: 'Redux',
    },
    {
      _id: '011',
      name: 'Material-UI',
    },
    {
      _id: '012',
      name: 'Bootstrap',
    },
    {
      _id: '013',
      name: 'Styled-Components',
    },
    {
      _id: '014',
      name: 'NodeJs',
    },
    {
      _id: '015',
      name: 'I18next',
    },
    {
      _id: '016',
      name: 'Strapi',
    },
    {
      _id: '017',
      name: 'Photoshop',
    },
    {
      _id: '018',
      name: 'Figma',
    },
    {
      _id: '019',
      name: 'Adobe XD',
    },
  ]
}