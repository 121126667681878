import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import '../../i18nextInit';

import Menu from '@mui/material/Menu';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { ListItem } from './styles';
import flagBrazil from '../../assets/images/flagBrazilIcon.png';
import flagEua from '../../assets/images/flagEuaIcon.png';

const languageMap = [
  { value: 'en', icon: flagEua, label: 'Inglês', dir: 'ltr', active: false },
  { value: 'pt_BR', icon: flagBrazil, label: 'Português', dir: 'ltr', active: true },
];

export function LanguageSelect() {
  const minLanguage = 1;
  const languageCount = Object.keys(languageMap).length;
  const selected = localStorage.getItem('i18nextLng') || 'pt_BR';
  const shouldRender = languageCount <= minLanguage ? false : true;
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    document.body.dir = languageMap[selected]?.dir;
  }, [anchorEl, selected]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    shouldRender ?
    <>
      <ButtonBase onClick={handleClick}>
        <img src={selected === 'pt_BR' ? flagBrazil : flagEua} alt="language" width="25px" height="25px" />
        <ArrowDropDownIcon color="inherit" />
      </ButtonBase>
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        id="customized-menu"
        keepMounted
        elevation={2}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <List disablePadding>
          {languageMap.map((item) => (
            <ListItem key={item.value}>
              <ButtonBase
                onClick={() => {
                  i18next.changeLanguage(item.value);
                  setAnchorEl(null);
                }}
              >
                <img src={item.icon} alt="flag" />
                <Typography variant="subtitle2">{item.label}</Typography>
              </ButtonBase>
            </ListItem>
          ))}
        </List>
      </Menu>
    </> : <></>
  );
}