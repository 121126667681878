import { styled } from '@mui/material/styles';
import MuiListItem from '@mui/material/ListItem';

export const ListItem = styled(MuiListItem)`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }

  &:hover {
    background: #333;
  }
`;