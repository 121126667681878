import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { ListStyled } from './styles';
import { LanguageSelect } from '../LanguageSelect';

export function Menu() {
  const { t } = useTranslation();
  
  return (
    <ListStyled>
      <ListItem disableGutters>
        <LanguageSelect />
      </ListItem>

      <ListItem disableGutters component={Link} to="/about" sx={{ margin: '0 3rem' }}>
        <ListItemText primary={
          <Typography variant="menu">{t('sobre')}</Typography>
        }/>
      </ListItem>

      <ListItem disableGutters component={Link} to="/my-portifolio">
        <ListItemText primary={
          <Typography variant="menu">{t('portifolio')}</Typography>
        }/>
      </ListItem>
    </ListStyled>
  );
}