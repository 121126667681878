import { useTranslation } from 'react-i18next';
import workImagine from '../assets/images/work-imagine.jpg';
import workJhowAlves from '../assets/images/work-jhowalves.jpg';
import workIbm from '../assets/images/work-ibm.jpg';
import workProxxi from '../assets/images/work-proxxi.jpg';
import workAsintese from '../assets/images/work-asintese.png';


export function TranslateWorks() {
  const { t } = useTranslation();

  const worksMock = {
    data: [
      {
        _id: '001',
        work: [
          {
            _id: '001',
            role: t('digitador'),
            timeWork: 'jan de 2013 - fev de 2014',
            description: t('descricao_analise_e_sintese'),
          },
        ],
        business: 'Análise e Síntese',
        city: 'São Paulo',
        country: 'Brasil',
        image: workAsintese,
      },
      {
        _id: '002',
        work: [
          {
            _id: '001',
            role: t('tecnico_de_suporte_a_sistemas'),
            timeWork: 'jan de 2014 - fev de 2016',
            description: t('descricao_ibm'),
          },
        ],
        business: 'IBM Brasil',
        city: 'São Paulo',
        country: 'Brasil',
        image: workIbm,
      },
      {
        _id: '003',
        work: [
          {
            _id: '001',
            role: t('assistente_tecnico'),
            timeWork: 'jan de 2017 - fev de 2020',
            description: t('descricao_proxxi'),
          },
        ],
        business: 'Proxxi',
        city: 'São Paulo',
        country: 'Brasil',
        image: workProxxi,
      },
      {
        _id: '004',
        work: [
          {
            _id: '001',
            role: t('desenvolvedor_front_end'),
            timeWork: 'jan de 2020 - o momento',
            description: t('descricao_jhonalves'),
          },
        ],
        business: 'JhowAlves',
        city: 'São Paulo',
        country: 'Brasil',
        image: workJhowAlves,
      },
      {
        _id: '005',
        work: [
          {
            _id: '001',
            role: t('desenvolvedor_front_end_trainee'),
            timeWork: 'dez de 2020 - dez de 2021',
            description: t('descricao_imagine_trainee'),
          },
          {
            _id: '002',
            role: t('desenvolvedor_front_end_jr'),
            timeWork: 'jan de 2022 - o momento',
            description: t('descricao_imagine_jr'),
          },
        ],
        business: 'Imagine Inovação',
        city: 'São Paulo',
        country: 'Brasil',
        image: workImagine,
      },
    ]
  }

  return worksMock;
}