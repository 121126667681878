import React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { Menu } from './Menu';
import { AppBar, Toolbar, Logo } from './styles';

export function Navbar() {
  const navigate = useNavigate();

  function goToHomePage() {
    navigate('/');
  }

  return (
    <AppBar position="sticky" elevation={0}>
      <Box position="absolute" top="0" left="0" width="100%">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Logo variant="h3" onClick={goToHomePage}>JHØN</Logo>
            <Menu />
          </Toolbar>
        </Container>
      </Box>
    </AppBar>
  );
}