import React from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { skillsMock } from '../../mocks/skillsMock';

import Chip from '@mui/material/Chip';
import Typography from'@mui/material/Typography';
import { GridItemStyled } from './styles';

export function Skills() {
  const skills = skillsMock;

  return (
    <Grid container>
      <GridItemStyled item xs={12}>
        {skills.data.map((item) => (
          <Stack direction="row" sx={{ m: 1 }} key={item._id}>
            <Chip
              variant="outlined" 
              label={
                <Typography variant="skills">
                  {item.name}
                </Typography>
              } 
            />
          </Stack>
        ))}
      </GridItemStyled>
    </Grid>
  );
}